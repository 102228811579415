<!--
服务订单
-->
<template>
  <div class="mainBox">
    <div class="shipTitle">
      <div class="changTitle">
        <div
          class="changeItme"
          :class="changeIndex == 1 ? 'changeItmeActive' : ''"
          @click="changeIndex = 1"
        >
          设备库
        </div>
      </div>
    </div>
    <!---->
    <div class="shipMain">
      <div class="shipNav">
        <img
          src="@/assets/img/homeGuardianship/pressTitle.png"
          alt=""
          class="titleIcon"
        />
        <span class="titleName">设备管理</span>
      </div>

      <!-- 设备管理 -->
      <div class="equipmentList">
        <div
          class="equipmentItems"
          v-for="(item, index) in userDeviceList"
          :key="index"
          @click="openInfo(item)"
        >
          <!-- <el-tooltip
            class="item"
            effect="dark"
            :content="item"
            placement="top-start"
          >
            <div class="tipName">湖北健康产湖北健康产</div>
          </el-tooltip> -->
          <img :src="item.equipmentUrl" alt="" class="equipmentIcon" />
          <div class="eq_name">{{ item.equipmentName }}</div>
          <!-- <div class="eq_price">
            <span class="p_count">￥</span>
            <span class="p_nums">1200.00</span>
          </div> -->
          <div class="eq_class">{{ item.equipmentNo }}</div>
        </div>
      </div>
    </div>

    <!-- 弹出层 -->
    <el-dialog title="" :visible.sync="showInfo" width="1000px">
      <div class="dialogMain">
        <!-- 标题 -->
        <div class="dialogTile">
          <div class="colorTip"></div>
          <div class="titleTxt">设备信息</div>
        </div>
        <!-- 设备信息 -->
        <el-row :gutter="20" class="infoRow">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 设备名称：</el-col>
              <el-col :span="12" class="infoRight">{{
                deciveInfo.equipmentName
              }}</el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 设备编号：</el-col>
              <el-col :span="12" class="infoRight">{{
                deciveInfo.equipmentNo
              }}</el-col>
            </el-row></el-col
          >
        </el-row>
        <el-row :gutter="20" class="infoRow">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 设备型号：</el-col>
              <el-col :span="12" class="infoRight">{{
                deciveInfo.equipmentModel
              }}</el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 租赁开始时间：</el-col>
              <el-col :span="12" class="infoRight">{{
                deciveInfo.startTime
              }}</el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="infoRow">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 租赁结束时间：</el-col>
              <el-col :span="12" class="infoRight">{{
                deciveInfo.finishTime
              }}</el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- 标题 -->
        <div class="dialogTile dialogTileMargin">
          <div class="colorTip"></div>
          <div class="titleTxt">安装信息</div>
        </div>
        <!-- 安装信息 -->
        <el-row :gutter="20" class="infoRow">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 安装状态：</el-col>
              <el-col :span="12" class="infoRight">
                <el-link
                  :underline="false"
                  type="info"
                  v-if="deciveInfo.isSetup == 0"
                  >未安装</el-link
                >

                <el-link
                  :underline="false"
                  g
                  type="success"
                  v-if="deciveInfo.isSetup == 1"
                  >已安装</el-link
                >
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="20" class="infoRow">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 安装人：</el-col>
              <el-col :span="12" class="infoRight">湖北健康</el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 安装时间：</el-col>
              <el-col :span="12" class="infoRight">2023-10-23</el-col>
            </el-row></el-col
          >
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 安装地址：</el-col>
              <el-col :span="12" class="infoRight">2023-10-23</el-col>
            </el-row></el-col
          >
        </el-row> -->
        <el-row :gutter="20" class="infoRow">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 安装前图片：</el-col>
              <el-col :span="12" class="infoRight">
                <el-image
                  :src="deciveInfo.installBeforeUrl"
                  fit="cover"
                  class="colImg"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 安装中图片：</el-col>
              <el-image
                :src="deciveInfo.installingUrl"
                fit="cover"
                class="colImg"
              >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image> </el-row
          ></el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 安装后图片：</el-col>
              <el-image :src="deciveInfo.installUrl" fit="cover" class="colImg">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image> </el-row
          ></el-col>
        </el-row>

        <!-- 标题 -->
        <div class="dialogTile dialogTileMargin">
          <div class="colorTip"></div>
          <div class="titleTxt">回收信息</div>
        </div>
        <!-- 回收信息 -->

        <el-row :gutter="20" class="infoRow">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 回收状态：</el-col>
              <el-col :span="12" class="infoRight">
                <el-link
                  :underline="false"
                  type="info"
                  v-if="deciveInfo.returnState == 0"
                  >未回收</el-link
                >

                <el-link
                  :underline="false"
                  g
                  type="success"
                  v-if="deciveInfo.returnState == 1"
                  >提前归还</el-link
                >
                <el-link
                  :underline="false"
                  g
                  type="danger"
                  v-if="deciveInfo.returnState == 2"
                  >到期回收</el-link
                ></el-col
              >
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 退款金额：</el-col>
              <el-col :span="12" class="infoRight">{{
                deciveInfo.returnAmou
              }}</el-col>
            </el-row></el-col
          >
        </el-row>
        <el-row :gutter="20" class="infoRow">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 回收人：</el-col>
              <el-col :span="12" class="infoRight">{{
                deciveInfo.returnUserName
              }}</el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 回收时间：</el-col>
              <el-col :span="12" class="infoRight">{{
                deciveInfo.returnTime
              }}</el-col>
            </el-row></el-col
          >
        </el-row>
        <el-row :gutter="20" class="infoRow">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="10" class="infoLeft"> 回收设备图片：</el-col>
              <el-col :span="12" class="infoRight">
                <el-image
                  :src="deciveInfo.returnUrl"
                  fit="cover"
                  class="colImg"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { GetUserDevice } from "../../api/homeGuardianship";
export default {
  components: {},
  data() {
    return {
      timeData: "",
      changeIndex: 1,
      src: "",
      showInfo: false,
      userDeviceList: "",
      deciveInfo: {},
    };
  },
  created() {
    this.getUserDevice();
  },

  mounted: function () {},
  destroyed: function () {},
  computed: {},
  watch: {},
  methods: {
    openInfo(info) {
      this.showInfo = true;
      this.deciveInfo = info;
    },
    getUserDevice() {
      GetUserDevice({ bizType: "3" }).then((res) => {
        let data = res.result;
        this.userDeviceList = data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.equipmentList {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.equipmentItems {
  width: 174px;
  height: 222px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 10px 10px;
  padding-top: 15px;
  text-align: center;
  margin-right: 28px;
  margin-bottom: 20px;
  color: #476bfe;
  position: relative;
  .tipName {
    padding: 0 8px;
    width: 86px;
    height: 22px;
    line-height: 22px;
    background: linear-gradient(
      90deg,
      rgba(153, 153, 153, 0.2) 0%,
      rgba(77, 77, 77, 0) 100%
    );
    border-radius: 2px 2px 2px 8px;
    position: absolute;
    left: 3px;
    bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
}
.equipmentIcon {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.eq_name {
  font-size: 14px;
  font-weight: 400;
  color: #476bfe;
  margin-bottom: 5px;
}
.eq_price {
  min-width: 100px;
  padding: 4px 10px;
  display: inline-block;
  background: rgba(71, 107, 254, 0.2);
  border-radius: 15px 15px 15px 15px;
  .p_count {
    font-size: 12px;
    font-weight: 400;
    color: #476bfe;
  }
  margin-bottom: 5px;
}
.eq_class {
  font-size: 12px;
  color: #476bfe;
}
.dialogMain {
  min-height: 470px;
}
.dialogTile {
  display: flex;
  align-content: center;
  .colorTip {
    width: 6px;
    height: 20px;
    background: #2176ff;
    border-radius: 1px 1px 1px 1px;
    margin-right: 10px;
  }
  .titleTxt {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
}
.infoRow {
  margin-top: 15px;
  font-size: 12px;
  line-height: 12px;
  .infoLeft {
    text-align: right;
  }
  .colImg {
    width: 86px;
    height: 86px;
  }
  .image-slot {
    line-height: 86px;
    font-size: 60px;
  }
}
.dialogTileMargin {
  margin-top: 20px;
}
</style>
